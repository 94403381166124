<template>
  <v-app style="background-color:#eee;padding:30px;">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('register')"
      class="px-5 py-3 col-md-5"
      color="primary"
      style="margin:auto;"
      v-if="!validate_notification"
    >
      <ValidationObserver ref="observer" v-slot="{ validate, reset }">
        <v-row>
          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }" :name="$t('company')" rules="required">
              <v-text-field
                v-model="user.company"
                :label="$t('company')"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }" :name="$t('email')" rules="required|email">
              <v-text-field
                v-model="user.email"
                :label="$t('email')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" :name="$t('first_name')" rules="required">
              <v-text-field
                v-model="user.firstname"
                :label="$t('first_name')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" :name="$t('last_name')" rules="required">
              <v-text-field
                v-model="user.lastname"
                :label="$t('last_name')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }" :name="$t('address')" rules="required">
              <v-text-field
                v-model="user.address"
                :label="$t('address')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="4">
            <ValidationProvider v-slot="{ errors }" :name="$t('city')" rules="required">
              <v-text-field
                v-model="user.city"
                :label="$t('city')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="4">
            <ValidationProvider v-slot="{ errors }" :name="$t('country')" rules="required">
              <v-text-field
                v-model="user.country"
                :label="$t('country')"
                class="purple-input"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="4">
            <ValidationProvider v-slot="{ errors }" :name="$t('postal_code')" rules="required">
              <v-text-field
                v-model="user.postal_code"
                class="purple-input"
                :label="$t('postal_code')"
                type="number"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider
              rules="required|min:6|password:@Confirm"
              v-slot="{ errors }"
              :name="$t('password')"
            >
              <v-text-field
                v-model="user.password"
                type="password"
                :label="$t('password')"
                class="purple-input"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider :name="$t('password')" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="user.repeatPassword"
                type="password"
                :label="$t('repeat_password')"
                class="purple-input"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn color="primary" class="mr-0" @click="register_user()">
              {{
              $t("confirm")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </base-material-card>

    <v-alert v-if="validate_notification" style="color:white;" color="primary">
      <h3>{{ $t("register_notification_title") }}</h3>
      <br />
      {{ $t("register_notification_body") }}
    </v-alert>
  </v-app>
</template>

<script>
import * as firebase from "firebase";
import slugify from "slugify";

export default {
  data() {
    return {
      user: {
        id: 0,
        company: "",
        email: "",
        firstname: "",
        lastname: "",
        address: "",
        city: "",
        country: "",
        postal_code: "",
        password: "",
        repeatPassword: "",
      },
      validate_notification: false,
    };
  },
  mounted() {},
  methods: {
    register_user: function (event) {
      this.$refs.observer.validate().then((result) => {
        if (result) {
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then((data) => {
              this.user.id = data.user.uid;

              let u = this.user;
              u.password = null;
              u.repeatPassword = null;
              u.verified = false;
              u.link = slugify(slugify(u.company), {
                remove: /[*+~.()'"!:@]/g,
              }).toLowerCase(); //u.company.toLowerCase().replace(/ /g, "-");
              this.$store.dispatch("SAVE_USER", {
                user: u,
              });
              this.$store.commit("SET_USER", u);

              //send email verification
              var actionCodeSettings = {
                url:
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/login",
              };
              firebase.auth().languageCode = "sl";
              var user = firebase.auth().currentUser;
              user
                .sendEmailVerification(actionCodeSettings)
                .then(function () {
                  // Email sent.
                })
                .catch(function (error) {
                  // An error happened.
                });
              this.validate_notification = true;
              //this.$router.push("Dashboard");
            })
            .catch((error) => {
              console.log(error);
              this.$notify({
                group: "foo",
                title: this.$t("error"),
                text: error.message,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
